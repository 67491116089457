import OrderRepositoryInterface from '../repositories/interfaces/OrderRepositoryInterface'

const bind = (repositoryName, Interface) => ({
	...Object.keys(Interface).reduce((prev, method) => {
		const resolveableMethod = async (...args) => {
			const repository = await import(
				'../repositories/' + repositoryName + '.js'
			)
			return repository.default[method](...args)
		}
		return { ...prev, [method]: resolveableMethod }
	}, {}),
})

export default {
	orderRepository: bind(
		'OrderRepository',
		OrderRepositoryInterface,
	),
}
