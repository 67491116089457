import MenuRepositoryInterface from '../repositories/interfaces/MenuRepositoryInterface'
import OrderRepositoryInterface from '../repositories/interfaces/OrderRepositoryInterface'
import PatientBedRepositoryInterface from '../repositories/interfaces/PatientBedRepositoryInterface'
import PatientsRepositoryInterface from '../repositories/interfaces/PatientsRepositoryInterface'
import OrderScheduleRepositoryInterface from '../repositories/interfaces/OrderScheduleRepositoryInterface'

const bind = (repositoryName, Interface) => ({
	...Object.keys(Interface).reduce((prev, method) => {
		const resolveableMethod = async (...args) => {
			const repository = await import(
				'../repositories/' + repositoryName + '.js'
			)
			return repository.default[method](...args)
		}
		return { ...prev, [method]: resolveableMethod }
	}, {}),
})

export default {
	menuRepository: bind(
		'MenuRepository',
		MenuRepositoryInterface,
	),
	orderRepository: bind(
		'OrderRepository',
		OrderRepositoryInterface,
	),
	patientBedRepository: bind('PatientBedRepository', PatientBedRepositoryInterface),
	patientsRepository: bind('PatientsRepository', PatientsRepositoryInterface),
	orderScheduleRepository: bind(
		'OrderScheduleRepository',
		OrderScheduleRepositoryInterface,
	),
}
