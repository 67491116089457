export default {
	getAll() {},
	getAllByPatient() {},
	getAllByAreaDate() {},
	getAllTypeDiet() {},
	find(id) {},
	save(id, data) {},
	delete(id) {},
	update(id, data) {},
}
