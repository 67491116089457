export default {
	getAll() {},
	getAllBrandByClient() {},
	getAllServicesByBrand() {},
	getAllAreaByService() {},
	getAllSubareaByArea() {},
	getAllLocationPatient() {},
	save(id, data) {},
	delete(id) {},
	update(id, data) {},
}
