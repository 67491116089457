import DisheRepositoryInterface from '../repositories/interfaces/DisheRepositoryInterface'
import AccompanimentRepositoryInterface from '../repositories/AccompanimentRepository'
import NutritionalInfoRepositoryInterface from '../repositories/interfaces/NutritionalInfoRepositoryInterface'
import MenuRepositoryInterface from '../repositories/interfaces/MenuRepositoryInterface'
import OrderRepositoryInterface from '../repositories/interfaces/OrderRepositoryInterface'
import OrderScheduleRepositoryInterface from '../repositories/interfaces/OrderScheduleRepositoryInterface'

const bind = (repositoryName, Interface) => ({
	...Object.keys(Interface).reduce((prev, method) => {
		const resolveableMethod = async (...args) => {
			const repository = await import(
				'../repositories/' + repositoryName + '.js'
			)
			return repository.default[method](...args)
		}
		return { ...prev, [method]: resolveableMethod }
	}, {}),
})

export default {
	disheRepository: bind('DisheRepository', DisheRepositoryInterface),
	accompanimentRepository: bind(
		'AccompanimentRepository',
		AccompanimentRepositoryInterface,
	),
	nutritionalInfoRepository: bind(
		'NutritionalInfoRepository',
		NutritionalInfoRepositoryInterface,
	),
	menuRepository: bind('MenuRepository', MenuRepositoryInterface),
	orderRepository: bind('OrderRepository', OrderRepositoryInterface),
	orderScheduleRepository: bind(
		'OrderScheduleRepository',
		OrderScheduleRepositoryInterface,
	),
}
