import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAll(limit = 90000, offset = 0) {
		const params = {
			order: 'desc',
			order_by: 'name',
			offset: offset,
			limit: limit,
			'filters[0][field]': 'accompaniment',
			'filters[0][operator]': '=',
			'filters[0][value]': 1,
		}
		const { data } = await useJwt.get(API_ROUTES.accompaniment.get, params)

		return data
	},
	save(id, data) {
		return useJwt.post(`${API_ROUTES.accompaniment.save}/${id}`, data)
	},
	delete(id) {
		return useJwt.delete(`${API_ROUTES.accompaniment.delete}/${id}`)
	},
	update(id, data) {
		return useJwt.update(`${API_ROUTES.accompaniment.update}/${id}`, data)
	},
}
